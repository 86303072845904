<template>
  <cp-general-modal
    ref="issuerVariable"
    :title="getTitleName"
    :title-icon="`ion ion-${titleData.icon}`"
    :form-group-value="variableData"
    @onOk="handleSubmit"
  >
    <div class="d-flex align-items-center mb-5">
      <cp-switcher
        name="active"
        :value="model.active"
        :disabled="titleData.type === 'Edit'"
      />
      <div class="flex-shrink-1 w-100 text-big ml-3">
        Active
      </div>
    </div>
    <cp-input
      v-model="model.name"
      name="name"
      label="Name"
    />
    <cp-input
      v-model="model.value"
      name="value"
      label="Value"
    />
    <cp-select
      v-model="model.access"
      name="access"
      label="Access"
      :options="accesses"
      disabled
    />
    <div
      v-if="titleData.type === 'Edit'"
      class="row mt-2"
    >
      <div class="col text-muted">
        <label class="form-label">
          Created
        </label> <br>
        <span>
          {{ model.createdAt | dateFilter }}
        </span>
      </div>
      <div class="col text-muted">
        <label class="form-label">
          Updated
        </label>  <br>
        <span>
          {{ model.updatedAt | dateFilter }}
        </span>
      </div>
    </div>
  </cp-general-modal>
</template>

<script>
import CpGeneralModal from '~/components/common/modals-components/general-modal';
import CpInput from '~/components/common/standalone-components/inputs/cp-input';
import CpSelect from '~/components/common/standalone-components/inputs/cp-select';
import CpSwitcher from '~/components/common/standalone-components/inputs/cp-switch';

export default {
  name: 'IssuerConfigVariableModal',
  components: {
    CpGeneralModal,
    CpInput,
    CpSelect,
    CpSwitcher,
  },

  props: {
    titleData: {
      type: Object,
      default: () => {},
    },
    variableData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      model: {},
      accesses: ['private', 'public'],
    };
  },

  computed: {
    getTitleName() {
      return `${this.titleData.type} Configuration Variable`;
    },
  },

  watch: {
    variableData: {
      handler(val) {
        this.model = Object.assign({}, val);
      },
    },
  },

  methods: {
    handleSubmit() {
      const action = this.model.id ? 'update' : 'create';
      this.$emit('onSubmit', { action, configVariable: this.model });
    },
  },
};
</script>
