const tdClass = 'align-middle';

export default [
  { key: 'name', tdClass },
  { key: 'section', tdClass },
  { key: 'access', tdClass },
  { key: 'active', tdClass },
  { key: 'value', tdClass },
  { key: 'actions', label: ' ', tdClass: `text-nowrap ${tdClass} text-center` },
];
