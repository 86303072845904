var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('cp-head-table',{attrs:{"title":"Issuer Configuration variables","title-icon":"ios-build"}}),_c('cp-table',{ref:"cpTable",attrs:{"get-data-action":"issuerConfigurationVariables/getIssuerConfigurationVariables","url-params":_vm.urlParams,"fields":_vm.fields,"filters":_vm.filtersData},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var rowData = ref.rowData;
return [_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.toEditModal(rowData.item)}}},[_vm._v(" "+_vm._s(rowData.item.name)+" ")])]}},{key:"active",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',{staticClass:"ion",class:{ 'ion-md-checkmark text-primary': rowData.item.active, 'ion-md-close text-light': !rowData.item.active }})]}},{key:"access",fn:function(ref){
var rowData = ref.rowData;
return [_c('b-badge',{attrs:{"variant":"outline-info"}},[_vm._v(" "+_vm._s(rowData.item.access)+" ")])]}},{key:"actions",fn:function(ref){
var rowData = ref.rowData;
return [_c('b-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(true),expression:"true",modifiers:{"hover":true}}],staticClass:"mr-3",attrs:{"variant":"default edit-operator-btn btn-xs md-btn-flat","title":"Edit"},on:{"click":function($event){return _vm.toEditModal(rowData.item)}}},[_c('i',{staticClass:"ion ion-md-create",style:({ color: '#04bec4' })}),_vm._v(" Edit ")])]}}])}),_c('cp-variable-modal',{ref:"CpVariableModal",attrs:{"title-data":_vm.titleData,"variable-data":_vm.modalData},on:{"onSubmit":_vm.saveChanges}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }